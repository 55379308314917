import * as React from 'react';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';
// markup
const NotFoundPage = () => {
  return (
    <>
      <Helmet>
        <title>G58 - Strona nie istnieje</title>
      </Helmet>
      <main>
        <h1 className='404__title'>Wystąpił błąd</h1>
      </main>
    </>
  );
};

export default NotFoundPage;
